﻿/**umb_name:Heading 2*/
h2 {
    color: #464b4f;
}
/**umb_name:Heading 3*/
h3 {
    color: #464b4f;
}
/**umb_name:Heading 4*/
h4 {
    color: #464b4f;
}
/**umb_name:paragraph*/
p {
    color: #464b4f;
}
/**umb_name:Dark Grey*/
.dark-grey-color {
    color: #464b4f;
}
/**umb_name:Blue*/
.blue-color {
    color: #009dd8;
}
/**umb_name:Violet*/
.violet-color {
    color: #6d2485;
}
/**umb_name:Lime*/
.lime-color {
    color: #abc52e;
}
/**umb_name:Magenta*/
.magenta-color {
    color: #ce025f;
}
/**umb_name:Dark Blue*/
.dark-blue-color {
    color: #003873;
}
/**umb_name:Yellow*/
.yellow-color {
    color: #fdb913;
}
/**umb_name:Orange*/
.orange-color {
    color: #f58220;
}
/**umb_name:White*/
.white-color {
    color: #FFF;
}
/**umb_name:Float Left*/
.float-left {
    float: left;
}
/**umb_name:Float Right*/
.float-right {
    float: right;
}
/**umb_name: Add Right Gutter*/
.pad-right {
    padding-right: 15px;
}
/**umb_name: Add Left Gutter*/
.pad-left {
    padding-left: 15px;
}
/**umb_name: Add Bottom Gutter*/
.pad-bottom {
    padding-bottom: 15px;
}
/**umb_name: Add Top Gutter*/
.pad-bottom {
    padding-top: 15px;
}

/**umb_name: Button*/
.button {
    line-height: 1;
    display: inline-block;
    transition: all 0.3s ease;
    font-family: 'Lato', sans-serif;
    padding: 12px 20px;
    font-size: 1.375em;
    font-weight: bold;
    line-height: 1;
    outline: none;
    border: none;
    position: relative;
    z-index: 1;
    text-align: center;
}

a:has(.orange-button), a:has(.lime-button), a:has(.blue-button), a:has(.red-button), a:has(.yellow-button), a:has(.teal-button) {
    text-decoration: none;
}

/**umb_name: Teal Button*/
.teal-button {
    background-color: #00949f;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.teal-button:hover, .teal-button:hover a {
    background-color: #004a4f;
}

/**umb_name: Yellow Button*/
.yellow-button {
    background-color: #fdb913;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.yellow-button:hover, .yellow-button:hover a {
    background-color: #865f01;
}

/**umb_name: Red Button*/
.red-button {
    background-color: #ce0260;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.red-button:hover, .red-button:hover a {
    background-color: #670030;
}

/**umb_name: Blue Button*/
.blue-button {
    background-color: #009dd8;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.blue-button:hover, .blue-button:hover a {
    background-color: #004e6c;
}

/**umb_name: Orange Button*/
.orange-button {
    background-color: #f58220;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.orange-button:hover, .orange-button:hover a {
    background-color: #e56e0a;
}
/**umb_name: Lime Button*/
.lime-button {
    background-color: #abc52e;
    padding: 1rem;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    width: fit-content;
}

.lime-button:hover, .lime-button:hover a {
    background-color: #99b129;
}

.orange-button a, .lime-button a, .blue-button a, .yellow-button a, .red-button a, .teal-button a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none !important;
}

