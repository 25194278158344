﻿/* 
    Header - This file is used for the <header /> template items for both the master, home and subpage
*/

/// <reference path="../init/variables.scss" />

#headerimg,
.headerimg {
    width: auto;
    margin-bottom: 40px;
}



header.main .navbar-expand-md {
    background-color: $white;
}

.blue-search{
    padding: 0;
}

.blue-hero {
    color: $white;
    background-color: $blue;
    padding: 60px 60px;

    p {
        color: $white;
    }

    h1 {
        font-weight: bolder;
        font-size: 2.5em;
    }
}

.circle {
    margin-bottom: -10px;
    margin-left: -40px;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    z-index: 2000;
}

.navbar .navbar-brand img
{
    margin-bottom: -300px;

}

header.main {


    .top-bar {
        background-color: $white;
        color: $blue;
        margin-left: 40px;
        width: auto;
        margin-right: 40px;
        line-height: 40px;
        z-index: 6000;
        vertical-align: top;

        .col-lg {
            z-index: 6000;
        }

        .alert-area {
            background-color: $white;
            z-index: 5000;

            ul.alert-list {
                margin-bottom: 0rem;

                li {
                    margin-bottom: 0;
                    white-space: nowrap;
                    color: $blue;
                    font-size: 0.875em;

                    span.alert-notice {
                        font-weight: bold;
                    }

                    a {
                        color: $blue;
                        font-family: "Proxima Nova", sans-serif;
                    }
                }
            }
        }

        .self-service-area {

            ul {
                float: right;
                text-align: right;
                list-style: none;
                margin: 0;
                padding: 0;
                padding-right: 0px;
                width: 100%;

                li {
                    line-height: 40px;
                    display: inline-block;
                    font-family: "Proxima Nova", sans-serif;
                    font-weight: normal;
                    font-size: 1em;
                    position: relative;
                    width: auto;
                    margin-right: 8px;
                    margin-left: 8px;
                    text-align: center;

                    a {
                        color: $blue;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }

    .navbar-expand-md {
        padding: 0;
        max-height: 60px;
    }

    .navbar-toggler {
        background-color: $blue;
        color: $white;
        width: 90px;
        border-radius: 0;

        .navbar-toggler-icon {
            float: left;
            padding-top: 5px;

            .menu {
                float: right;
                width: 80%;
                padding-left: 5px;
            }

            .dots {
                float: left;
                width: 20%;
            }
        }
    }

    ul.navbar-nav {
        position: absolute;
        display: block;
        float: right;
        right: 0;
        vertical-align: top;
        list-style: none;
        text-align: center;
        padding-top: 0px;
        margin: 0 auto;
        background-color: $white;

        li {
            font-size: 18px;
            line-height: 20px;
            display: inline-block;
            position: relative;

            a.nav-link {
                font-weight: 400;
                color: $primary;
                display: block;
                margin: 0 10px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: $blue;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }

                &:hover {
                    &:before {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }

            ul.dropdown {
                display: none;
                position: absolute;
                background-color: #F5F5F5;
                min-width: 200px;
                box-shadow: 5px 8px 16px 5px rgba(0,0,0,0.2);
                z-index: 101;
                text-align: left;
                padding: 10px 0;

                li {
                    font-family: 'Lato', sans-serif;
                    font-size: 0.8em;
                    line-height: 20px;
                    display: block;
                    position: relative;

                    a {
                        color: $blue;
                        display: block;
                        margin: 0;
                        padding: 8px 25px;
                        font-weight: 600;
                        position: relative;
                        text-decoration: none;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background-color: $blue;
                            visibility: hidden;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transition: all 0.3s ease-in-out 0s;
                            transition: all 0.3s ease-in-out 0s;
                        }

                        &:hover {
                            &:before {
                                visibility: visible;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }

            &:hover {
                ul.dropdown {
                    display: block;
                }
            }
        }
    }
}



@media screen and (max-width: 991px)
{
    .navbar .navbar-brand img {
        margin-bottom: -320px;
    }

    .circle {
        margin-bottom: 90px;
    }

    header.main .top-bar .col-lg {
        text-align: center;
    }

    #headerimg, .headerimg {
/*        margin-right: 40px;
        margin-left: 40px;*/
        margin-bottom:0;
    }

    .blue-hero {
        padding: 10px 30px;

        h1 {
            margin: 1rem 0;
            font-size:2rem;
        }
    }

    .hero-image {
        height: 200px;
    }

}


@media screen and (max-width: 1600px) {
    .navbar .navbar-brand img {
        margin-left: 1rem;
        max-width: 180px;
    }
}

@media screen and (max-width: 767px) {
    header.main .top-bar .self-service-area ul
    {
        text-align: center;
        float: none;
        margin-bottom: 10px;
    }
    #headerimg, .headerimg {
        margin-right: 20px;
        margin-left: 20px;
    }

    header.main .navbar-toggler {
        margin-top: -160px !important;
    }
    .navbar .navbar-brand img {
        margin-bottom: -370px;
        width: 160px;
    }

    .circle {
        margin-top: -170px;
    }

    

    ul li.newsitem
    {
        padding-right: 0;
    }

    header.main .top-bar
    {
        position: relative;
    }



   

    
}

