﻿/// <reference path="../init/variables.scss" />

.footer-links a {
    color: $secondary;
    font-weight: bold;
}



footer.main {
    background-color: #F9F9F9;
    padding-top: 70px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;




    figure.footer-logo {

        img {
            max-width: 240px;
        }

        .social-icons:hover img {
            opacity: 0.9;
        }

        .social-icons {
            max-width: 225px;
            text-align: right;

            img {
                padding: 15px 5px;
            }
        }
    }

    .footer-location {
        font-family: "Proxima Nova", sans-serif;
        color: #515151;

        p.address {
            line-height: 25px;
        }

        p {
            line-height: 30px;
        }

        p, p.address {

            a {
                color: $blue;
            }

            a:hover {
                color: $secondary;
            }

            img {
                padding-right: 10px;
            }
        }
    }

    .footer-introduction {
        padding-bottom: 20px;

        h3 {
            font-family: "Proxima Nova", sans-serif;
            font-size: 1.375em;
        }

        p {
            font-family: "Proxima Nova", sans-serif;
            font-size: 1em;
            line-height: 20px;
        }
    }
}

    footer.sub-footer {
        color: $white;
        font-family: 'Lato', sans-serif;
        background-color: $blue;
        padding: 15px 0 5px 0;

        .navigation {
            padding-bottom: 5px;

            ul {
                list-style: none;
                text-align: center;
                padding: 0;
                margin: 0;

                li {
                    font-size: 0.875em;
                    line-height: 20px;
                    display: inline-block;
                    position: relative;

                    a {
                        color: $white;
                        font-weight: bold;
                        display: block;
                        margin: 0 18px;
                        position: relative;
                    }

                    a:hover {
                        transition: .3s $secondary;
                    }
                }
            }
        }

        .copyright {
            p {
                font-size: 0.750em;
                font-weight: bold;
                color: $white;
            }
        }
    }



    .footer-links ul {
        list-style: none;
        line-height: 30px;
        padding-left: 10px;
    }

    .footer-links {
        padding-left: 0;
    }

    @media screen and (max-width: 1023px) {
    }

    @media screen and (min-width:1024px){
        footer.main {
            padding-left: 100px;
            padding-right: 100px;
        }
    }


    @media screen and (max-width: 991px) {

        .container.navigation {
            padding-left: 0 !important;
            margin-left: 20px !important;
        }

        .footer-links ul {
            list-style: none;
            line-height: 30px;
            padding-left: 0px !important;
        }


    }

    @media screen and (max-width: 767px) {

        .container.navigation {
            padding-left: 0 !important;
            margin-left: 20px !important;
        }
    }