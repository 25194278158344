﻿/// <reference path="../init/variables.scss" />
.umbraco-forms-page {
    font-family: 'Lato', sans-serif;
    display: block;
    margin-bottom: 5px;
    color: $dark_blue;
    padding: 20px;
    background-color: $white;
    margin: 30px 0;

    .umbraco-forms-caption {
        color: $dark_blue;
        text-align: center;
    }

    .umbraco-forms-fieldset {
        width: 100%;
        font-size: 1em;

        legend {
            font-size: 1.2rem;
            text-align: center;
        }

        .umbraco-forms-container {
            .multiplechoice, .singlechoice{
                margin-bottom:10px;
            }
            .message{
                textarea{
                    height:60px;
                }
            }
            .dataconsent {
                margin-bottom: 20px;
                color: $violet;
            }

            label {
                margin-bottom: 0;
            }

            input[type=text] {
                width: 100%;
                margin-bottom: 10px;
                height: 40px;
                border: 1px solid #cccccc;
            }

            textarea {
                width: 100%;
                margin-bottom: 10px;
                border: 1px solid #cccccc;
            }

            select {
                width: 100%;
                height: 40px;
                border: 1px solid #cccccc;

                option {
                    color: $violet;
                }
            }
            
            .field-validation-error {
                color: $magenta
            }
        }
    }

    .umbraco-forms-navigation {
        .btn {
            line-height: 1;
            display: inline-block;
            color: #FFF;
            text-transform: uppercase;
            padding: 15px 30px;
            width: 100%;
            transition: all 0.3s ease;
            font-family: 'Lato', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            outline: none;
            border: none;
            border-radius: 0;
            position: relative;
            z-index: 1;
            text-align: center;
            font-size: 1.375em;
            background-color: $lime_green;
        }
    }
}
.umbraco-forms-submitmessage {
    font-size: 1.2em;
    color: $dark_blue;
    margin-bottom: 20px;
    font-weight: bold;
}