﻿section.featured-books {

    .books-slideshow {
        height: 400px;
 
    }
}

.slick-prev,
.slick-next
{
    font-size: 30px !important;
    line-height: 0;
    position: absolute;
    top: 40%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
    color: #464b4f;
    border: none;
    outline: none;
    background: none !important;
}
.slick-prev
{
    left: -25px;
}

.slick-next
{
    right: -25px;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
}

.display-materials {
    h3 {
        padding-bottom: 15px;
    }

    .materials-slideshow {
        padding-bottom: 20px;

        .pull-left, .pull-right {
            position: absolute;
            top: -54px;
        }

        .pull-left {
            left: 195px;
        }

        .pull-right {
            left: 225px;
        }
    }

    figure {
        width: 200px;
        margin-right: 10px;

        figcaption {
            p.material-name {
                margin-top: 10px;
                color: #464b4f;
                text-align: center;
                font-weight: bold;
                font-family: "Proxima Nova", sans-serif;
                line-height: 25px;
            }

            p.author-name {
                color: #464b4f;
                text-align: center;
                font-family: "Proxima Nova", sans-serif;
                font-weight: 400;
                line-height: 0;
            }
        }
    }
}

ul {
    li {
        &.newsitem
            {
            padding: 0 1rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    ul li.newsitem {
        /*padding: 0;*/
    }

    section#featured-news-and-events{
        /*padding:0;*/
    }
}




