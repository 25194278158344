﻿/// <reference path="../init/variables.scss" />
body {
    color: #515151;
    font-family: "Proxima Nova", sans-serif;
}
h3, h4, h5, h3 a, h4 a, h5 a {
    color: #515151;
    font-family: "Proxima Nova", sans-serif;
}
a.back-to-top {
    display: none;
    width: 60px;
    height: 60px;
    text-indent: -9999px;
    position: fixed;
    z-index: 999;
    left: 20px;
    bottom: 20px;
    background: #ce025f url("/media/1330/up-arrow.png") no-repeat center 43%;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
