﻿.button {
    line-height: 1;
    display: inline-block;
    /*background-color: $light_grey;*/
    color: #FFF;
    padding: 15px 30px;
    border-radius: 6px;
    transition: all 0.3s ease;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    outline: none;
    border: none;
    position: relative;
    z-index: 1;
    text-align: center;

    &.medium {
        padding: 12px 20px;
        font-size: 1.375em;
    }

    &.small {
        padding: 8px 10px;
        font-size: 1em;

        &:not(.orange) {
            text-transform: none;
        }
    }

    &.large {
        padding: 22px 35px;
    }

    &.view-catalog {
        font-size: 1.125em;
    }

    &.homepage {
        font-size: 1.125em;
        display: block;
        margin: auto;
        width: 50%;
    }

    &.fullwidth {
        width: 100%;
    }

    &.orange {
        background-color: $orange;
    }

    &.blue {
        background-color: $blue;
    }

    &.blue:hover {
        color: $white;
    }

    &.lime {
        background-color: $lime_green;
    }
}
@media screen and (max-width: 1024px){
    .button{
        &.homepage{
            width:90%;
        }
    }
}
