﻿$primary: #006169;
$secondary: #464b4f;//font color
$blue: #009dd8;
$violet: #6d2485;
$lime_green: #abc52e;
$magenta: #ce025f;
$green: #00939f;
$dark_blue: #003873;
$yellow: #fdb913;
$orange: #f58220;
$white: #fff;
