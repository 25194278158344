﻿.page {
    overflow-x: hidden;
    position: relative;
}

.wrapper {
    position: relative;
    top: 0;
    -webkit-transition: ease 0.5s;
    -moz-transition: ease 0.5s;
    -o-transition: ease 0.5s;
    transition: ease 0.5s;
}

.wrapper.pushed {
    -webkit-transform: translateX(-250px);
    -moz-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    -o-transform: translateX(-250px);
    transform: translateX(-250px);
}

.panel, .subPanel {
    width: 250px;
    background: #f58220;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: -250px;
    -webkit-transition: ease 0.5s;
    -moz-transition: ease 0.5s;
    -o-transition: ease 0.5s;
    transition: ease 0.5s;
    overflow: hidden;
}

.panel {
    z-index: 10;
    border-left: 1px solid #f58220;
}

.panel ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.panel ul li {
    border-bottom: 1px solid #fff;
    color: white;
    cursor: pointer;
    padding: 15px;
}

.panel ul li a {
    color: white;
}

.openSubPanel a, .subPanel a {
    color: white;
    text-decoration: none;
}

.panel ul li:hover, panel ul li a:hover {
    background: #009dd8;
    color: #fff;
}

.panel ul li ul li {
    color: white;
}

.panel ul li ul li:hover {
    color: #fff;
}


.panel.isOpen, .subPanel.isOpen {
    -webkit-transform: translateX(-250px);
    -moz-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    -o-transform: translateX(-250px);
    transform: translateX(-250px);
}

.subPanel {
    z-index: 20;
}
