﻿/// <reference path="../init/variables" />

body{

}


.wrapper{
    max-width:1500px;
    margin:0 auto;
}

main, .breadcrumb-area {
    max-width: 1400px;
    margin: 0 auto;
}

.breadcrumb-area {
    max-width: 1400px;
    margin: 1rem auto 0;
}

main h2, main h3, main h4, main h5 {
    margin-top: 2rem;
}

a {
    color: $blue;
}

#subpageHeader {
    background-color: $blue;
}

h2 {
    font-weight: bold;
}

section.introduction {
    padding: 50px 0;

    h2 {
        color: $dark_blue;
        font-family: "Proxima Nova", sans-serif;
        font-size: 3.063em;
        text-align: center;
    }

    p {
        font-family: "Proxima Nova", sans-serif;
        font-size: 1.5em;
        text-align: center;
        color: $secondary;
    }
}

section#featured-news-and-events figure a:hover figcaption p,
section#featured-news-and-events figure a:hover figcaption,
a:hover figure figcaption,
a:hover figure,
a:hover figcaption {
    text-decoration: none !important;
}

.newsitem {
    a:hover {
        p {
            text-decoration: none !important;
            color: #464b4f !important;
        }

        h4 {
            text-decoration: underline;
        }
    }
}

.slick-track a:hover {
    text-decoration: none;
    opacity: 0.9;

    figure {


        figcaption {

            p {
                text-decoration: none;
            }
        }
    }
}


section#featured-news-and-events {
    background-color: #ededed;
    padding: 20px 0 15px 0;

    ul#newsitemlist {

        padding-left: 0;
        margin-bottom: 0;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left:20px;
        margin-right:20px;
    }

    .pull-left, .pull-right {
        position: absolute;
        cursor: pointer;
        top: 200px;
        font-size: 50px;
        z-index: 1;
        background-color: rgba(222, 222, 222, 0.05);
        color: #c1c1c1;
        border: none;
        outline: none;
    }

    .pull-right {
        right: -20px;
    }

    .pull-left {
        left: -20px;
    }

    .featured-book:hover p {
        text-decoration: none !important;
    }

    a:hover {
        text-decoration: none;
    }

    figure {
        img {
            width: 100%;
        }

        figcaption {
            background-color: $white;

            h2 {
                font-size: 1.6em;
                font-weight: 400;
                padding-top: 10px;
                text-align: left;
                color: $secondary;
                padding: 24px 30px 10px 30px;
                font-family: "Proxima Nova", sans-serif;
                margin: 0;
            }

            p {
                color: #464b4f;
                padding: 0px 30px;
                margin-top: 6px;
                text-align: left;
                font-family: "Proxima Nova", sans-serif;
                padding-bottom: 2rem;
            }

            p a {
                color: #464b4f;
            }

            .more-details-bottom {
                width: 100%;
                height: 50px;
                position: relative;

                a.newsitem-link {
                    font-family: "Proxima Nova", sans-serif;
                    font-weight: bold;
                    font-size: 1em;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}


.spotlights {
    width: 100%;
    background-color: $blue;
    padding: 1rem 2rem;

    a.spotlight {
        display:block;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 40px;
        min-height: 760px;
        text-decoration: none;
        margin:1rem 0;

        img {
            padding-bottom: 20px;
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }

        img:hover {
            opacity: 0.9;
        }

        h2 {
            color: #515151;
            font-size: 1.75em;
            margin-top: 2rem;
            font-weight:400;
        }
    }
}

@media screen and (max-width: 1024px) {
    section#searchbar {
        padding-left: 20px;
    }

    .spotlights {
        padding: 1rem 1rem;
    }
}



section.featured-books {
    background-color: $white;
    padding: 2rem;

    h1 {
        color: $blue;
        font-family: "Proxima Nova", sans-serif;
        font-size: 2em;
        float: left;
    }

    .books-selection-desktop {
        float: left;
        width: 100%;
        padding: 20px 0;

        #featured-filter {
            float: right;
            margin-top: 20px;

            label {
                display: block;
                color: $secondary;
                margin-bottom: 0px;
                float: left;
                margin-top: 8px;
            }
        }

        #featured-book-filter {
            width: 150px;
            height: 40px;
            background-color: $white;
            margin-top: 0px;
            padding: 0 10px;
            border: none;
            color: $blue;
            float: right;
        }
    }

    .desktop-only {
        float: none;
        margin-top: 30px;
    }




    #books-slideshow {
        padding-bottom: 40px;


        .featured-book {
            width: 175px;

            figure {
                margin: 0 10px;
                position: relative;

                figcaption {
                    display: block;
                    width: 100%;
                    height: 20px;
                    color: $secondary;
                    margin-right: 5px;
                    position: relative;
                    margin-top: 10px;
                    line-height: 20px;
                    bottom: 0;
                    max-width: 175px;
                    left: 0;
                    right: 0;

                    .material-name {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    p {
                        text-align: center;
                        padding: 0px 0;
                        color: $secondary;
                    }

                    a {
                        color: $secondary;
                    }
                }

                &:hover {
                }
            }
        }
    }

    .button.fullwidth {
        display: none;
    }
}

section.services-research {
    padding: 20px 0;
    /*margin: 30px 0; desktop only*/
    .services-research-navigation {
        padding-top: 40px;
        background-color: darken($white,4%);

        ul {
            list-style: none;
            padding-left: 0;
            padding-top: 20px;

            li {
                padding-bottom: 15px;

                a {
                    color: $secondary;
                    font-family: "Proxima Nova", sans-serif;
                    font-size: 1.5em;
                }

                img {
                    padding-right: 15px;
                }
            }
        }
    }

    .services-research-description {
        padding-top: 50px;
        padding-bottom: 20px;

        h2 {
            font-family: "Proxima Nova", sans-serif;
            font-size: 2.125em;
        }

        p {
            font-family: "Proxima Nova", sans-serif;
            font-size: 1em;
        }

        .research-options {
            padding-top: 20px;

            figure {
                img {
                    display: block;
                    margin: 0 auto;
                }

                figcaption {
                    color: $violet;
                    font-family: "Proxima Nova", sans-serif;
                    font-size: 1.250em;

                    a {
                        color: $violet;
                    }
                }
            }
        }
    }
}

section.upcoming-events {
    background-color: $violet;
    color: $white;
    padding-top: 10px;

    #events-slideshow {
        margin: 20px;
        list-style: none;
        padding-left: 12px;

        .pull-left-purple, .pull-right-purple {
            position: absolute;
            cursor: pointer;
            top: 70px;
            font-size: 30px;
            padding: 0 5px;
            background-color: darken($violet, 10%);
            color: lighten($violet, 40%);
            border: none;
            outline: none;
        }

        .pull-right-purple {
            right: -20px;
        }

        .pull-left-purple {
            left: -20px;
        }

        li {
            border-right: #5e1f70 2px solid;
            padding: 0 20px;

            .date {
                padding-right: 20px;

                .month {
                    background-color: lighten($orange, 10%);
                    padding: 2px 20px;
                }

                .day {
                    font-family: "Proxima Nova", sans-serif;
                    color: black;
                    background-color: white;
                    padding: 2px 10px;
                    text-align: center;
                    font-size: 2.0em;
                }
            }

            .description {
                margin-left: 88px;

                h3 {
                    font-family: "Proxima Nova", sans-serif;
                    font-size: 1.625em;
                    color: $white;
                }

                p {
                    font-family: "Proxima Nova", sans-serif;
                    font-size: 1em;
                    color: $white;
                }
            }

            &:first-child {
                padding-top: 0;
                border-top: none;
            }
        }
    }

    .view-events {
        background-color: darken($violet,10%);
        font-family: "Proxima Nova", sans-serif;
        font-weight: bold;
        padding: 10px 0 5px 0;
        vertical-align: top;
        line-height: 50px;

        p {
            font-size: 1.375em;

            a {
                color: $white;

                img {
                    padding-right: 15px;
                }
            }
        }
    }
}

section#searchbar {
    width: 100%;
    /* Disabling height because I added the library-selector radio buttons in.        
        height: 70px;
    */

    margin-top: 75px;

    input[type=text] {
        width: 70%;
        padding: 20px 0;
        font-family: "Proxima Nova", sans-serif;
        font-size: 1.375em;
        background-image: url('/../../assets/src/img/search.jpg');
        background-repeat: no-repeat;
        background-position: 2%;
        text-indent: 70px;
        border: none;
        border-radius: 6px;
    }


    label.library-selector {
        color: $white;
    }

    button {
        color: white;
        background-color: $blue;
        width: 21%;
        border: solid 3px #fff;
        border-radius: 6px;
        padding-top: 22px;
        padding-bottom: 20px;
        margin-left: 4px;
        font-weight: 600;
    }

    button:hover {
        border: solid 3px #b2d1dd;
    }
    /*button:after {
        font-family: "FontAwesome";
        content: '\f054';
    }*/

    #selectors {
        padding: 15px 0 10px 0;
    }

    input[type=radio] {
        width: 25px;
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

.breadcrumb-area {
    background-color: $white;
    padding-left: 100px;
    padding-right: 100px;

    .breadcrumb {
        background-color: $white;
        padding-left: 0;
        font-family: "Proxima Nova", sans-serif;
        font-size: 1em;
        margin-bottom: 0;

        li {
            a {
                color: #ABABAB;
            }
        }

        li.active {
            color: lighten($secondary, 40);
        }

        .divider {
            padding: 0 15px;
            color: lighten($secondary, 20);
            font-weight: bold;
        }
    }
}

h2.subpage-header {
    color: $secondary;
    font-family: "Proxima Nova", sans-serif;
    font-weight: bold;
    text-align: center;
}

h1.subpage-header {
    color: $white;
    font-family: "Proxima Nova", sans-serif;
    font-size: 3em;
    text-align: left;
    padding-bottom: 60px;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 60px;
}

.subpage, .additional-field {
    padding-left: 100px !important;
    padding-right: 100px !important;

    p {
        font-family: "Proxima Nova", sans-serif;
        color: $secondary;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }
    /*a {
        color: $dark_blue;

        &:hover {
            color: $lime_green;
        }
    }*/
}

#featured-news-and-events .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
}

.row-no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

#subpageHeader {
    margin-bottom: -8px;
}

#subpage-header-image {
    /*.navbar-expand-md{
        padding:0 0 10px 0;
    }*/


    .navbar-brand {
        position: absolute;
        padding-top: 50px;
    }

    section#searchbar {
        width: 75%;
        float: right;

        input[type=text] {
            padding: 10px 0;
        }

        button {
            padding-top: 17px;
            padding-bottom: 12px;
        }
    }
}

img.location-image { /* for location pages force the image to be 400px on all devices.*/
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 850px;
    width: 100%;
    padding-bottom: 20px;
}

.additional-field {
    background-color: none;
    padding: 0;
    margin: 40px 0;

    .col-md-6 {
        justify-content: center;
    }

    hr.blueline {
        border: none;
    }

    .searchresults {
        strong {
            color: $violet;
            text-transform: uppercase;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                padding-bottom: 5px;
            }
        }
    }

    .resource-guides-list {
        ul {
            list-style-type: none;
            padding-left: 15px;
        }
    }

    ul.list-unstyled {
        li.media {
            border-bottom: 2px dotted $lime_green;
            margin-bottom: 20px;
            /*img {
                max-width: 400px;
            }*/
        }
    }

    .staff-picks {
        figure {
            max-width: 260px;

            img {
                width: 100%;
            }

            figcaption {
                max-width: 260px;

                h5 {
                    padding-top: 5px;
                    text-align: center;
                }
            }
        }

        .row {
            margin-bottom: 20px;
        }
    }
}

.container-fluid {
    margin: 0;
    padding: 0;
}

#subpageAdditionalContentArea {
    padding-bottom: 0px;
    padding-top: 40px;



    h4 strong {
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        display: inline-block;
    }

    ul li, ul li a {
        font-weight: bold;
        margin-right: 10px;
        float: left;
    }
}

.barker {
    margin: 0 0 2rem 0;
    text-align: center;
    box-shadow: rgba(0,0,0,.2) 0 0 3px;
    text-decoration: none;

    h3 {
        font-size: 18px;
        font-weight: 700;
        margin-top: 0rem;
        padding: 1rem;
    }
}

.barker:hover {
    background-color: #009dd8;


    h3 {
        color: #fff;
    }

    a {
        text-decoration: none;
    }
}

section#featured-news-and-events {
    figure {
        figcaption {
            min-height: 200px;


            p {
                min-height: 80px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    section#searchbar {
        width: 100%;
        margin-top: 50px;

        input[type=text] {
            text-indent: 50px;
        }
    }
}

@media screen and (max-width: 500px) {
    .library-selector {
        display:block;
    }
}

@media screen and (max-width: 1024px) {

    #headerimg, .headerimg {
        margin-bottom: 0;
    }

    h1.subpage-header {
        color: $white;
        font-family: "Proxima Nova", sans-serif;
        font-size: 2em;
        text-align: left;
        padding-bottom: 60px;
        margin-bottom: 0;
        padding-left: 20px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
    }

    .navbar-header .collapse, .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-expand-md .navbar-toggler {
        display: block;
    }

    .navbar-expand-md {
        justify-content: space-between;
    }

    section.introduction {
        padding: 40px 0 0 0;

        h2 {
            font-size: 1.750em;
        }

        p {
            font-size: 1em;
        }
    }

    section#featured-news-and-events {
        padding:0;

        li.newsitem {
            width: 100%;

            figure {
                figcaption {
                    h4 {
                        padding: 25px 30px;
                        margin: 0 1rem;
                    }

                    

                    /* p {
                                display: none;
                            }*/
                }
            }
        }
    }

    section.featured-books {
        padding-top: 35px;

        h1 {
            float: left;
        }

        .books-selection-desktop {
            width: 100%;
            padding: 0;
        }


        .desktop-only {
            display: none;
        }

        #featured-book-filter {
            width: 110px;
            height: 40px;
            background-color: $white;
            padding: 0 10px;
            border: none;
            color: $blue;
        }

        #books-slideshow {
            padding-top: 20px;
        }

        .button.fullwidth {
            display: block;
        }
    }

    section.upcoming-events {
        #events-slideshow {
            li {
                border-top: #5e1f70 2px solid;
                border-right: none;
                padding-top: 20px;
            }
        }
    }

    #subpage-header-image, #headerimg, #subpageHeader {
        section#searchbar {
            .search {
                display: none;
            }

            input[type=text] {
                width: 78%;
                height: 50px;
                background-image: none;
                text-indent: 15px;
                font-size: 18px;
            }

            #selectors {
                padding: 10px 0 20px 0;
            }

            label.library-selector {
                color: $white;
                font-size: 0.75em;
                font-family: "Proxima Nova", sans-serif;
                font-weight: bold;
            }

            input[type=radio] {
                width: 25px;
                -ms-transform: scale(1.5); /* IE 9 */
                -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
                transform: scale(1.5);
            }

            button {
                margin-top: 20px;
                color: white;
                background-image: url('/../../assets/src/img/search.png');
                background-repeat: no-repeat;
                background-position: 50%;
                background-color: $blue;
                border-radius: 6px;
                border: 3px solid #fff;
                width: 16%;
                padding-top: 30px;
                padding-bottom: 18px;
                font-weight: 600;
            }
        }
    }

    section.services-research {
        padding-top: 0;

        .services-research-description {
            padding: 0 20px;

            .research-options {
                padding: 0;
            }
        }
    }

    h1.subpage-header {
        padding-bottom: 40px;
    }

    #subpage-header-image {
        .navbar-brand {
            position: relative;
            padding-top: 0;
        }

        .navbar-expand-md {
            padding: 25px 0 35px 0;
        }

        section#searchbar {
            width: 100%;
            float: none;
        }
    }
}

.slick-dots {
    /*display: flex;*/
    /*justify-content: center;*/
    display: block;
    list-style: none;
    margin: 12px auto 0;
    padding: 0;
    text-align: center;

    li {
        margin: 0 6px;

        button {
            background: #98A7AC;
            height: 30px;
            width: 30px;
            overflow: hidden;
            color: #98A7AC;
            border: none;
            border-radius: 50%;
            display: inline-block;
        }
    }
}

.slick-dots li.slick-active button {
    background: #6E6E6E;
    color: #6E6E6E;
    border: none;
}



.slick-dots-container {
    width: 5.5rem;
    overflow: hidden;
    display: block;
    padding: 0;
    margin: 0.625rem auto;
    height: 0.875rem;
    position: relative;
}

.slick-dots-container > ul {
    padding: 0;
    display: flex;
    transition: all 0.25s;
    position: relative;
    margin: 0;
    list-style: none;
    transform: translateX(0);
    align-items: center;
    bottom: unset;
    height: 100%;
}

.slick-dots-container > ul li {
    width: 0.625rem;
    height: 0.625rem;
    margin: 0 0.25rem;
    background-color: #efd5d5;
    border: none;
    border-radius: 50%;
}

.slick-dots-container > ul li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.3125rem;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.slick-dots-container > ul li.p-small-1,
.slick-dots-container > ul li.n-small-1 {
    transform: scale(0.8);
}

.slick-dots-container > ul li.slick-active {
    transform: scale(1.3);
    transform-origin: center;
    background: #3498db;
}

.slick-dots li button:before {
    display: none;
}
/* limit dots*/
/* hiding all bullets by default */
.slick-dots li {
    display: none
}
/* only displaying the active bullets and the 2 bullets next to it */
.slick-dots li.slick-active,
.slick-dots li.slick-active + li,
.slick-dots li.slick-active + li + li {
    display: inline-block;
}
/* displaying the last three bullets when slick-active class isn't applied to any li before them  */
.slick-dots li:nth-last-child(1),
.slick-dots li:nth-last-child(2),
.slick-dots li:nth-last-child(3) {
    display: inline-block;
}
/* hiding the last three bullets if slick-active exist before them */
.slick-dots li.slick-active ~ li:nth-last-child(1),
.slick-dots li.slick-active ~ li:nth-last-child(2),
.slick-dots li.slick-active ~ li:nth-last-child(3) {
    display: none;
}
/* specific conditions to always display the last three bullets */
.slick-dots li.slick-active + li + li:nth-last-child(3),
.slick-dots li.slick-active + li + li:nth-last-child(2),
.slick-dots li.slick-active + li + li:nth-last-child(1),
.slick-dots li.slick-active + li:nth-last-child(3),
.slick-dots li.slick-active + li:nth-last-child(2),
.slick-dots li.slick-active + li:nth-last-child(1) {
    display: inline-block;
}


.detail-view {
    padding-top: 0px !important;
}




@media screen and (max-width: 991px) {


    .slick-list{
        background-color:#fff;
    }

    section#featured-news-and-events {
        padding: 0 0px;

        .newsitem {
            padding: 0 !important;
        }

        figure {
            figcaption {
                min-height: 160px;

                p {
                    min-height: 60px;
                }
            }
        }

        .slick-prev, .slick-next {
            top: 180px;
            width: 40px;
            height: 40px;
            font-size: 8px;
            border-radius: 50px;
            text-align: center;
        }

        .slick-prev {
            left: -40px;
        }

        .slick-next {
            right: -40px;
        }
    }

    .subpage, .additional-field {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .detail-view {
        padding-top: 0px !important;
    }

    .display-materials {
        padding-top: 0px !important;
    }

    .breadcrumb-area {
        background-color: $white;
        padding-left: 40px;
        padding-right: 40px;
    }



    section#searchbar input[type=text] {
        font-size: 1em;
        text-indent: 50px;
    }

    section#searchbar button {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}

@media screen and (max-width: 520px) {
    section#featured-news-and-events {
        figure {
            figcaption {
                min-height: 220px;

                p {
                    min-height: 60px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {

    .blue-hero section#searchbar {
        padding-left: 0;
    }

    .blue-hero section#searchbar {
        margin-top: 0px;
    }


}

@media screen and (max-width: 767px) {

    #featured-news-and-events .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }

    #headerimg, .headerimg {
        margin-right: 20px;
        margin-left: 20px;
    }
}


@media screen and (max-width: 420px) {

    section#searchbar input[type=text] {
        font-size: 0.9em !important;
    }
}
/*
*   CSS file with Bootstrap grid classes for screens bigger than 1600px. Just add this file after the Bootstrap CSS file and you will be able to juse col-xl, col-xl-push, hidden-xl, etc.
*
*   Author: Marc van Nieuwenhuijzen
*   Company: WebVakman
*   Site: WebVakman.nl
*
*/
@media (min-width: 1200px) and (max-width: 1599px) {
    .hidden-lg {
        display: none !important;
    }
}


.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl {
    display: none !important;
}



@media (min-width: 1600px) {
    .container {
        width: 1570px;
    }

    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-11 {
        width: 91.66666667%;
    }

    .col-xl-10 {
        width: 83.33333333%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-8 {
        width: 66.66666667%;
    }

    .col-xl-7 {
        width: 58.33333333%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-5 {
        width: 41.66666667%;
    }

    .col-xl-4 {
        width: 33.33333333%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-2 {
        width: 16.66666667%;
    }

    .col-xl-1 {
        width: 8.33333333%;
    }

    .col-xl-pull-12 {
        right: 100%;
    }

    .col-xl-pull-11 {
        right: 91.66666667%;
    }

    .col-xl-pull-10 {
        right: 83.33333333%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-8 {
        right: 66.66666667%;
    }

    .col-xl-pull-7 {
        right: 58.33333333%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-5 {
        right: 41.66666667%;
    }

    .col-xl-pull-4 {
        right: 33.33333333%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-2 {
        right: 16.66666667%;
    }

    .col-xl-pull-1 {
        right: 8.33333333%;
    }

    .col-xl-pull-0 {
        right: auto;
    }

    .col-xl-push-12 {
        left: 100%;
    }

    .col-xl-push-11 {
        left: 91.66666667%;
    }

    .col-xl-push-10 {
        left: 83.33333333%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-8 {
        left: 66.66666667%;
    }

    .col-xl-push-7 {
        left: 58.33333333%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-5 {
        left: 41.66666667%;
    }

    .col-xl-push-4 {
        left: 33.33333333%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xl-push-2 {
        left: 16.66666667%;
    }

    .col-xl-push-1 {
        left: 8.33333333%;
    }

    .col-xl-push-0 {
        left: auto;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xl-offset-0 {
        margin-left: 0;
    }

    .visible-xl {
        display: block !important;
    }

    table.visible-xl {
        display: table;
    }

    tr.visible-xl {
        display: table-row !important;
    }

    th.visible-xl, td.visible-xl {
        display: table-cell !important;
    }

    .visible-xl-block {
        display: block !important;
    }

    .visible-xl-inline {
        display: inline !important;
    }

    .visible-xl-inline-block {
        display: inline-block !important;
    }

    .hidden-xl {
        display: none !important;
    }
}
